import styled from "styled-components"
import dog from '../images/Dog.png'
import ad1 from '../images/Ads 1.png'
import ad2 from '../images/Ads 2.png'
import ad3 from '../images/Ads 3.png'
import { useEffect, useState } from 'react'

const WebStyle = styled.section`
  width: 100%;
  min-width: 400px;
  height: 66.5vh;
  background: cornsilk;
  overflow-y: auto;
  @media (max-width: 768px) {
    font-size: 0.8em;
  };
`
const WebHeader = styled.div`
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    height: 90px;
    margin-bottom: 10px;
  };
`
const Logo = styled.img.attrs(() => ({
  src: dog,
  alt: 'Dashround the Daschund'
}))`
  height: 130px;
  width: 130px;
  margin-right: 50px;
  @media (max-width: 768px) {
    width: 65px;
    height: 65px;
  };
`
const LogoText = styled.section`
  color: brown;
  font-weight: bold;
  font-size: 2.5em;
  @media (max-width: 768px) {
    font-size: 2em;
  };
`

const WebBody = styled.div`
  display: flex;
  align-items: start;
`
const Nav = styled.section`
  width: 15%;
  height: 100%;
`
const NavItem = styled.section`
  border-radius: 8px;
  background: LightSkyBlue;
  padding: 5px;
  margin: 5px;
  &:hover {
    background-color: LightCyan;
  }
`
const NavItems = [
  'Search',
  'Channels',
  'Local',
  'News',
  'Weather'
]

const Main = styled.section`
  width: 70%;
  height: 100%;
  padding: 5px;
`
const MainArticle = styled.section`
  font-size: .9em;
  padding: 10px;
  text-align: justify;
  
`
const MainTitle = styled.a.attrs((props) => ({
  href: props.href
}))`
  font-size: 1.1em;
`

const Ads = styled.section`
  width: 15%;
  min-width: 105px;
  height: 400px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 65px;
    min-width: unset;
  };
`
const AdsContent = [ad1, ad2, ad3]
const AdHolder = styled.img.attrs((props) => ({
  src: props.src,
  alt: 'Fake Ad'
}))`
  height: 100px;
  width: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    margin-top: 10px;
  };
`

export function Web () {
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(true)
  const [navTrigger, setNavTrigger] = useState(false)

  const onClickNav = (e) => {
    setNavTrigger(!navTrigger)
  }

  useEffect(() => {
    const pager = [getContent(), getContent(), getContent(), getContent(), getContent()]
    Promise.all(pager)
      .then(pages => {
        setPages(pages)
      })
      .catch((err) => {
        console.log('Something went wrong fetching some random pages')
      })
  }, [navTrigger])

  function getContent() {
    return new Promise((resolve, reject) => {
      setLoading(true)
      fetch('https://en.wikipedia.org/api/rest_v1/page/random/summary')
        .then((response) => response.json())
        .then((data) => {
          setLoading(false)
          resolve(data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  return (
    <WebStyle>
      <WebHeader><Logo/><LogoText>Dash!</LogoText></WebHeader>
      <WebBody>
        <Nav>
          {NavItems.map((item, id) =>
            <NavItem key={id}
                     onClick={onClickNav}>
              {item}</NavItem>
          )}
        </Nav>
        <Main>
          {loading && <div>Dashing...</div>}

          {pages && pages.map((item, id) => {
            return (
              <MainArticle key={id}>
                <MainTitle href={item.content_urls.desktop.page}>{item.title}</MainTitle><br/>
                {item.extract}
              </MainArticle>
            )
          })}
        </Main>
        <Ads>
          {AdsContent.map((item, id) =>
            <AdHolder src={item} key={id}/>
          )}
        </Ads>
      </WebBody>
    </WebStyle>
  )
}