import styled from "styled-components"

const QualStyle = styled.section`
  text-align: left;
  padding: 20px;
  padding-left: 30px;
  height: 80%;
  overflow-y: auto;
  @media (max-width: 768px) {
    font-size: 0.8em;
    height: 83%;
  };
`
const HStyle = styled.section`
  font-size: 1.2em;
  font-weight: bold;
`
const IStyle = styled.section`
  font-size: 0.7em;
  font-style: italic;
  margin-bottom: 15px;
`
export function Qualifications () {
  return (
    <QualStyle>
      <HStyle>Formal Qualifications</HStyle>
      <ul>
        <li>MSc, IT with Network Management (with Distinction)
          <IStyle>Robert Gordon University, Aberdeen – 2017-2018</IStyle>
        </li>
        <li>MEng, Electronic and Electrical Engineering
          <IStyle>Robert Gordon University, Aberdeen – 2003-2007</IStyle>
        </li>
        <li>Full UK Driving License
          <IStyle>No Endorsements – 2004</IStyle>
        </li>
      </ul>
      <HStyle>Other Skills and Training</HStyle>
      <ul>
        <li>GDPR in a nutshell - 2nd Edition <IStyle>Udemy (online) - 2024</IStyle></li>
        <li>Angular - The Complete Guide (2024 edition) <IStyle>Udemy (online) - 2024</IStyle></li>
        <li>DevSecOps with Azure DevOps <IStyle>Udemy (online) - 2024</IStyle></li>
        <li>Azure DevOps Crash Course - Build CI/CD Release Pipelines <IStyle>Udemy (online) - 2024</IStyle></li>
        <li>Software Architecture Security - The Complete Guide <IStyle>Udemy (online) - 2024</IStyle></li>
        <li>Secure Product Lifecycle 101 <IStyle>Udemy (online) - 2023</IStyle></li>
        <li>Unit Testing for Typescript & NodeJs Developers with Jest <IStyle>Udemy (online) - 2023</IStyle></li>
        <li>Unit Testing And Test Driven Development in NodeJS <IStyle>Udemy (online) - 2023</IStyle></li>
        <li>Best Practices & Advanced Typescript Tips for React Devs <IStyle>Udemy (online) - 2023</IStyle></li>
        <li>Analyze Business Metrics with SQL <IStyle>Codecademy (online) - 2022</IStyle></li>
        <li>Unity Essentials Pathway <IStyle>Unity (online) - 2021</IStyle></li>
        <li>Analyze Business Metrics <IStyle>Codecademy (online) - 2022</IStyle></li>
        <li>Norwegian Level 1 <IStyle>Evening course from Aberdeen University - 2016</IStyle></li>
        <li>Norwegian Level 2 <IStyle>Evening course from Aberdeen University - 2017</IStyle></li>
        <li>MIET – Member, Institute of Engineering and Technology <IStyle>2008-2018</IStyle></li>
      </ul>
    </QualStyle>
  )
}