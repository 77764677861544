import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { TextStyle } from '../components/TextStyle'

const numberOfCells = 1053

const DefragContainer = styled.section`
  margin-left: auto;
  margin-right: auto;
`
const DefragStyle = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 550px;
  height: 400px;
  align-content: flex-start;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  };
`
const Cell = styled.section`
  background: ${props => (props.colour > 0.5) ? 'pink' : 'lightgreen'};
  width: 12px;
  height: 12px;
  border: 1px solid grey;
  @media (max-width: 768px) {
    width: 7px;
    height: 7px;
  };
`
const DefragButton = styled.button`
  background: lightgrey;
  padding: 10px;
  border-radius: 8px;
  border: 2px grey;
  border-style: outset;
  margin-left: 20px;
  @media (max-width: 768px) {
    padding: 5px;
    margin-left: 10px;
  };
  &:hover {
    background-color: darkgrey;
    border-style: inset;
  }
`

export function Defrag () {
  const [running, setRunning] = useState(false)
  const cells = []
  for (let i = 0; i < numberOfCells; i++) {
    cells.push(Math.random())
  }

  const onGoClick = () => {
    setRunning(true)
    setCells(document.getElementsByName('dfCell'), 0)
  }
  function setCells (cells, index) {
    if (cells[index] && (index < numberOfCells)) {
      cells[index].style.background = 'slateblue'
      setTimeout(function() {
        setCells(cells, ++index)
      }, 10)
    } else {
      setRunning(false)
    }
  }

  const onCheckClick = () => {
    document.getElementsByName('dfCell').forEach((cell) => {
      cell.style.background = (Math.random() > 0.5) ? 'pink' : 'lightgreen'
    })
  }
  useEffect(() => {
    onCheckClick()
  }, [])

  return (
    <DefragContainer>
      <DefragStyle>
        {cells.map((box, id) =>
          <Cell name={'dfCell'} colour={box} key={id}/>
        )}
      </DefragStyle>
      <DefragButton id={'goButton'}
                    onClick={onGoClick}
                    disabled={running}><TextStyle>Defrag</TextStyle></DefragButton>
      <DefragButton id={'resetButton'}
                    onClick={onCheckClick}
                    disabled={running}><TextStyle>Scan Drive</TextStyle></DefragButton>
    </DefragContainer>
  )
}