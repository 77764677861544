import styled from "styled-components"
import { StartMenu } from './StartMenu'
import { useState } from 'react'
import { TextStyle } from './TextStyle'
import computer from '../images/Computer.png'

const StartBtn = styled.section`
  background: ${props => props.inOut ? 'darkgrey' : 'lightgrey'};
  width: 120px;
  height: 35px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: ${props => props.inOut ? 'inset' : 'outset'};
  border-radius: 8px;
  @media (max-width: 768px) {
    width: 100px;
    font-size: smaller;
  };
  &:hover {
    background-color: darkgrey;
  }
`
const StartIcon = styled.img.attrs(() => ({
  src: computer,
  alt: 'Computer icon'
}))`
  height: 35px;
  width: 45px;
  padding-right: 10px;
  @media (max-width: 768px) {
    height: 30px;
    width: 40px;
    padding-right: 5px;
  };
  &:hover {
    background-color: darkgrey;
  }
`

export function StartButton({setShowHistory, setShowQuals, setShowContact, setShowWeb}) {
  const [showStart, setShowStart] = useState(false)

  const toggleStartMenu = () => {
    setShowStart(!showStart)
  }

  return (
    <div>
      <StartBtn id="startButton"
                onClick={toggleStartMenu} inOut={showStart}>
        <StartIcon />
        <TextStyle>Begin</TextStyle>
      </StartBtn>
      {showStart && <StartMenu setShowHistory={setShowHistory}
                               setShowContact={setShowContact}
                               setShowQuals={setShowQuals}
                               setShowWeb={setShowWeb}
                               setShowStart={setShowStart}/>}
    </div>
  )
}