import styled from "styled-components"

const MenuWrapper = styled.section`
  width: 150px;
  height: 30px;
  position: absolute;
  top: 32px;
  left: -0px;
  border-right: 1px solid darkgrey;
  border-bottom: 1px solid darkgrey;
  justify-content: center;
  align-content: center;
`
const ItemStyle = styled.section`
  border-bottom: 1px solid darkgrey;
  background: lightgrey;
  color: ${(props) => (props.name === 'Close') ? 'black' : 'darkgrey'};
  width: 150px;
  height: 30px;
  box-shadow: 3px 3px 10px grey;
  padding-top: 5px;
  &:hover {
    background: darkgray;
  }
`
const Items = [
  'New', 'Open', 'Edit'
]


export function FileMenu ({close}) {
  return (
    <MenuWrapper>
      {Items.map((item, id) => (
        <ItemStyle key={id} name={item}>{item}</ItemStyle>
      ))}
      <ItemStyle key={4} name={'Close'} onClick={close}>
        Close</ItemStyle>
    </MenuWrapper>
  )
}