import styled from "styled-components"

import items from '../assets/items.json'
import { FileHolder } from '../components/FileHolder'
import { PopupHolder } from '../components/PopupHolder'
import { useState } from 'react'
import { BottomBar } from '../components/BottomBar'
import { Background } from '../components/Background'
import { LargePopup } from '../components/LargePopup'
import { Blurb } from '../components/Blurb'

import file from '../images/Folder.png'
import textFile from '../images/Doc.png'
import picture from '../images/Picture.png'
import settings from '../images/Settings.png'
import bin from '../images/Bin.png'
import sevenSeg from '../images/screens/7seg.JPG'
import about from '../images/screens/about.JPG'
import asdf from '../images/screens/asdf.JPG'
import gallery from '../images/screens/Gallery.JPG'
import keychain from '../images/screens/keychain.JPG'
import l4c from '../images/screens/l4c.JPG'
import mosaic from '../images/screens/mosaic.JPG'
import path from '../images/screens/path.JPG'
import tq from '../images/screens/tq.JPG'
import icpo from '../images/screens/ICPO.png'
import addresses from '../images/screens/addresses.png'
import SearchFacets from '../images/screens/SearchFacets.png'
import VirtualVessels from '../images/screens/VirtualVessels.PNG'
import RovWelcome from '../images/screens/Welcome to ROVs.PNG'
import { About } from '../docs/About'
import { History } from '../docs/History'
import { Qualifications } from '../docs/Qualifications'
import { Web } from '../docs/Web'
import { Contact } from '../docs/Contact'
import { Defrag } from '../docs/Defrag'
import { Scanlines } from '../components/Scanlines'
import { Pictures } from '../components/Pictures'

const HomePageStyle = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px;
`
const BinStyle = styled.section`
  padding: 20px;
  position: fixed;
  bottom: 10%;
  right: 5%;
`

export const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false)
  const [showFolder, setShowFolder] = useState('')
  const [showWeb, setShowWeb] = useState(false)
  const [showHistory, setShowHistory] = useState(false)
  const [showQuals, setShowQuals] = useState(false)
  const [showContact, setShowContact] = useState(false)
  const [showDefrag, setShowDefrag] = useState(false)
  const [showPictures, setShowPictures] = useState(false)

  const images = [
    {name: 'sevenSeg', img: sevenSeg},
    {name: 'about', img: about},
    {name: 'asdf', img: asdf},
    {name: 'gallery', img: gallery},
    {name: 'keychain', img: keychain},
    {name: 'l4c', img: l4c},
    {name: 'mosaic', img: mosaic},
    {name: 'path', img: path},
    {name: 'tq', img: tq},
    {name: 'SearchFacets', img: SearchFacets},
    {name: 'VirtualVessels', img: VirtualVessels},
    {name: 'RovWelcome', img: RovWelcome},
    {name: 'icpo', img: icpo},
    {name: 'addresses', img: addresses},
  ]

  const FileClicked = (file) => {
    if (file.name === 'Defrag') {
      setShowDefrag(true)
    } else if (file.name === 'Pictures') {
      setShowPictures(true)
    } else {
      setShowPopup(file)
    }
    //todo animate popup opening?
  }
  const ShowFolder = (id) => {
    setShowFolder(id)
  }
  const PopupCloseClicked = (title) => {
    switch (title) {
      case 'History':
        setShowHistory(false)
        break;
      case 'Qualifications':
        setShowQuals(false)
        break;
      case 'Internet':
        setShowWeb(false)
        break;
      case 'Contact':
        setShowContact(false)
        break;
      case 'Defrag':
        setShowDefrag(false)
      break;
      case 'Pictures':
        setShowPictures(false)
      break;
      default:
        setShowPopup(false)
        break;
    }
  }
  const FolderCloseClicked = () => {
    setShowFolder(false)
  }

  return (
    <Scanlines>
      <Background>
        <HomePageStyle>
          {items.folders.map((item) =>
            <FileHolder key={item.id} thumb={file} label={item.name} onClick={() => ShowFolder(item.id)} />
          )}
          {showFolder && (showFolder !== 'c') &&
            <PopupHolder title={items.folders.filter((item) => { return item.id === showFolder})[0].name} close={FolderCloseClicked} corner={'Folder'}>
              {items.folders.filter((item) => {return item.id === showFolder})[0].files.map((item) =>
                <FileHolder key={item.id} thumb={textFile} label={item.name} altText={item.altText} onClick={() => FileClicked(item)}/>
              )}
            </PopupHolder>
          }

          {items.files.map((item) =>
            <FileHolder key={item.id}
                        thumb={(item.id === 8) ? picture : (item.id === 9) ? settings : textFile}
                        label={item.name}
                        altText={item.altText}
                        onClick={() => FileClicked(item)}/>
          )}

          {showPopup && (showPopup.name==='About.txt') &&
            <PopupHolder title={showPopup.name} close={PopupCloseClicked} corner={'File'}>
              <About />
            </PopupHolder>
          }
          {showPopup && (showPopup.name!=='About.txt') &&
            <LargePopup title={showPopup.name} close={PopupCloseClicked} corner={'Info'}>
              <Blurb image={images.filter((img) => {return img.name === showPopup.thumb})[0].img}
                     altText={showPopup.altText}
                     text={showPopup.content}
                     link={showPopup.link}/>
            </LargePopup>
          }
        </HomePageStyle>

        <BinStyle>
          <FileHolder key={'c'} thumb={bin} label={'Deleted'} onClick={() => ShowFolder('c')} />
        </BinStyle>
        {showFolder && (showFolder === 'c') &&
          <PopupHolder title={'Deleted'} close={FolderCloseClicked} corner={'Deleted'}>
            {items.deleted.map((item) =>
              <FileHolder key={item.id} thumb={textFile} label={item.name} altText={item.altText} onClick={() => FileClicked(item)}/>
            )}
          </PopupHolder>
        }

        {showHistory && <LargePopup title={'History'} close={() => PopupCloseClicked('History')} corner={'Info'}><History/></LargePopup>}
        {showQuals && <LargePopup title={'Qualifications'} close={() => PopupCloseClicked('Qualifications')} corner={'Info'}><Qualifications/></LargePopup>}
        {showWeb && <LargePopup title={'Internet'} close={() => PopupCloseClicked('Internet')} corner={'Info'}><Web/></LargePopup>}
        {showContact && <PopupHolder title={'Contact'} close={() => PopupCloseClicked('Contact')} corner={'Info'}><Contact/></PopupHolder>}
        {showDefrag && <PopupHolder title={'Defrag'} close={() => PopupCloseClicked('Defrag')} corner={'B:\\>'}><Defrag/></PopupHolder>}
        {showPictures && <PopupHolder title={'Pictures'} close={() => PopupCloseClicked('Pictures')} corner={'Info'}><Pictures/></PopupHolder>}

        <BottomBar setShowHistory={setShowHistory}
                   setShowContact={setShowContact}
                   setShowQuals={setShowQuals}
                   setShowWeb={setShowWeb}
                   showPopup={showPopup}
                   showFolder={showFolder}
                   showHistory={showHistory}
                   showQuals={showQuals}
                   showWeb={showWeb}
                   showContact={showContact}
                   showDefrag={showDefrag}
                   showPictures={showPictures}/>
      </Background>
    </Scanlines>
  )
}
//todo - Stop flex-row-reorder on drag and drop
//todo - SSL for InfinityFree sites