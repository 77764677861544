import styled from "styled-components"
import Recaptcha from 'react-google-recaptcha'

import dish from '../images/Dish.png'
import radio from '../images/Radio.png'
import details from '../images/Deets.png'
import { useState } from 'react'

const Wrapper = styled.section`
  width: 100%;
  height: 100%;
`
const ContactStyle = styled.section`
  display: flex;
  flex-direction: row;
  padding: 50px;
  justify-content: space-around;
  @media (max-width: 768px) {
    padding: 25px;
  };
`
const ImageStyle = styled.img.attrs((props) => ({
  src: props.thumb,
  alt: props.altText,
}))`
  width: 100px;
  height: 100px;
  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  };
`
const DetailHolder = styled.img.attrs((props) => ({
  src: props.thumb,
  alt: props.altText,
}))`
  opacity: ${props => props.opacity};
  width: 200px;
  height: 100px;
  margin-left: auto;
  margin-right: auto;
`
const SiteKey = process.env.REACT_APP_G_SITE_KEY

export function Contact () {
  const [opacity, setOpacity] = useState(0)
  const onCaptcha = (e) => {
    if (e.length) {
      setOpacity(1)
    }
  }
  return (
    <Wrapper>
      <ContactStyle>
        <ImageStyle thumb={dish} altText={'Transmission'} />
        <div>
          <Recaptcha sitekey={SiteKey} onChange={onCaptcha}/>
        </div>
        <ImageStyle thumb={radio} altText={'Reception'} />
      </ContactStyle>
      <div>
        <p>Click above to connect.</p>
      </div>
      <DetailHolder id={'showDeets'} thumb={details} altText={'Details'} opacity={opacity}/>
    </Wrapper>
  )
}
//todo - add email as env param