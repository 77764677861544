import styled from "styled-components"

const LoadingPaneStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: black;
`
const TextStyle = styled.p`
  color: ${props => (props.delay === 9) ? 'red' : (props.delay === 4) ? 'yellow' : 'blue'};
  font-size: larger;
  text-align: left;
  font-family: "Lucida Console",serif;
  animation: show ${props => props.delay/3.5}s;
  @keyframes show {
    0% {
      color: black;
    }
    90% {
      color: black;
    }
    100% {
      color:${props => (props.delay === 8) ? 'red' : 'blue'};
    }
  }
`
const Text = [
  `Starting fans...`,
  `Checking IRQ configuration...`,
  ``,
  `Engaging CPU turbo rev limiter`,
  `Waiting for clock to stabilise...`,
  `Warming-up CRT display...`,
  ``,
  `Starting Tabletop Window Interface Tool...`,
  ``,
  `Welcome Brendan Campbell!`
]

export function LoadingScreen () {
  return (
    <LoadingPaneStyle>
      {Text.map((row, index) => {
        return <TextStyle key={index} delay={index}>{row}</TextStyle>
      })}
    </LoadingPaneStyle>
  )
}