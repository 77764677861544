import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: justify;
`
const Button = styled.a`
  display: block;
  text-decoration: none;
  padding: .5em;
  cursor: pointer;
  background: lightgrey;
  color: black;
  min-width: 50px;
  width: 100px;
  margin: 3px;
  border-style: outset;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-color: darkgray;
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 120px;
    margin: 1px;
    font-size: smaller;
  };
  &:hover {
    background: darkgray;
    border-style: inset;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 1em;
`

export const Pictures = () => {
  return (
    <Wrapper>
      Flickr's CORS policy blocks me from embedding my page as an iFrame and
      their embeddable code doesn't work, so click below to open my Flickr
      channel in a new tab.
      <ButtonWrapper>
        <Button href={`https://www.flickr.com/bobfantastic`}
                target={'_blank'}>Go To Flickr</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}