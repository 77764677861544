import styled from "styled-components"
import { findZ } from './FindZ'

const BackgroundItem = styled.section`
  //background: lightblue;
  height: 100vh;
  pointer-events: auto;
`

const onDrop = (e) => {
  e.preventDefault()
  e.stopPropagation()
  const data = JSON.parse(e.dataTransfer.getData("text/html"))
  document.getElementById(data[0]).style.position = 'fixed'
  document.getElementById(data[0]).style.left = e.clientX - data[1] + 'px'
  document.getElementById(data[0]).style.top = e.clientY - data[2] + 'px'
  if (data[3] && data[3] === 'file') {
    //nothing
  } else {
    document.getElementById(data[0]).style.zIndex = '' + findZ()
  }
}

const allowDrop = (e) => {
  e.preventDefault()
  e.stopPropagation()
}

export function Background({children}) {
  return (
    <BackgroundItem
      onDrop={(e) => onDrop(e)}
      onDragOver={(e) => allowDrop(e)}>
      {children}
    </BackgroundItem>
  )
}
//todo - prevent all scrolling on mobile?