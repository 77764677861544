import styled from "styled-components"
import { TaskBar } from './TaskBar'
import { StartButton } from './StartButton'
import { TaskbarButton } from './TaskbarButton'

const BottomBarStyle = styled.section`
  background: lightgrey;
  width: 100%;
  height: 50px;
  position: fixed;
  border-top: 1px solid darkgrey;
  bottom: 0;
  z-index: 1000;
`
const BottomFlexContainer = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 140px;
  border-left: 2px solid darkgray;
  padding-left: 5px;
  @media (max-width: 768px) {
    //flex-direction: column;
    //overflow-y: scroll;
    border-left: none;
    min-width: 150px;
  };
`

export function BottomBar({
  setShowHistory, setShowQuals, setShowContact, setShowWeb,
  showPopup, showFolder, showHistory, showQuals,
  showWeb, showContact, showDefrag, showPictures
}) {
  return (
    <BottomBarStyle>
      <StartButton setShowHistory={setShowHistory}
                   setShowContact={setShowContact}
                   setShowQuals={setShowQuals}
                   setShowWeb={setShowWeb}/>
      <BottomFlexContainer>
        {showPopup && <TaskbarButton label={showPopup.name} />}
        {(showFolder==='a') && <TaskbarButton label='Programs' />}
        {(showFolder==='b') && <TaskbarButton label='empty' />}
        {(showFolder==='c') && <TaskbarButton label='Deleted' />}
        {(showFolder==='d') && <TaskbarButton label='Case Studies' />}
        {showHistory && <TaskbarButton label='History' />}
        {showQuals && <TaskbarButton label='Qualifications' />}
        {showWeb && <TaskbarButton label='Internet' />}
        {showContact && <TaskbarButton label='Contact' />}
        {showDefrag && <TaskbarButton label='Defrag' />}
        {showPictures && <TaskbarButton label='Pictures' />}
      </BottomFlexContainer>
      <TaskBar />
    </BottomBarStyle>
  )
}