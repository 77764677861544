import styled from 'styled-components'

const BlurbWrapper = styled.section`
  height: 90%;
  overflow-y: scroll;
`
const BlurbImg = styled.img.attrs((props) => ({
  src: props.image
}))`
  box-shadow: 5px 5px 10px grey;
  margin-bottom: 20px;
  margin-top: 10px;
  max-width: 90%;
  height: auto;
  max-height: 500px;
`
const BlurbText = styled.section`
  margin-left: 30px;
  margin-right: 30px;
  text-align: justify;
  @media (max-width: 768px) {
    margin: 10px;
  }
`
const ImgWrapper = styled.a.attrs((props) => ({
  href: props.href || '#',
  target: (props.href) ? "_blank" : ''
}))`

`

export function Blurb({image, altText, text, link}) {
  return (
    <BlurbWrapper>
      <ImgWrapper href={link}>
        <BlurbImg image={image} altText={altText}/>
      </ImgWrapper>
      <BlurbText>{text}</BlurbText>
    </BlurbWrapper>
  )
}

//todo - text formatting