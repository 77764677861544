import styled from "styled-components"
import { TextStyle } from './TextStyle'
import { useState } from 'react'
import { FileMenu } from './FileMenu'

const PopupTopStyle = styled.section`
  background: lightgrey;
  height: 30px;
  border: 1px solid darkgrey;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const FileButton = styled.section`
  height: 30px;
  width: 60px;
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background: lightgray;
  &:hover {
    background: darkgray;
  }
`
const CloseButton = styled.section`
  height: 30px;
  width: 30px;
  border-left: 1px solid black;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  background: lightgray;
  &:hover {
    background: darkgray;
  }
`

export function PopupTop({title, close, corner}) {
  const [showMenu, setShowMenu] = useState(false)
  const onFileClicked = (e) => {
    setShowMenu(!showMenu)
  }

  return (
    <PopupTopStyle>
      <FileButton onClick={onFileClicked}>{corner}</FileButton>
      <TextStyle>{title}</TextStyle>
      <CloseButton onClick={close}>X</CloseButton>

      {showMenu && <FileMenu close={close} />}
    </PopupTopStyle>
  )
}