import styled from "styled-components"

const PopupBodyStyle = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
    font-size: smaller;
  };
`

export function PopupBody({children}) {
  return (
      <PopupBodyStyle>{children}</PopupBodyStyle>
  )
}