import styled from "styled-components"

import items from '../assets/items.json'
import web from '../images/Web.png'
import certs from '../images/Certs.png'
import history from '../images/Books.png'
import phone from '../images/Phone.png'
import power from '../images/Power.png'

const StartMenuStyle = styled.section`
  background: lightgrey;
  width: 20%;
  min-width: 220px;
  height: 40%;
  position: fixed;
  bottom: 50px;
  border: 1px solid grey;
  box-shadow: 5px -5px 10px grey;
  display: flex;
`
const LeftBar = styled.section`
  width: 50px;
  height: 100%;
  background-image: linear-gradient(180deg, cornflowerblue, grey);
  border-right: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: start;
`
const BarText = styled.section`
  margin-top: 60px;
  color: black;
  transform: rotate(270deg);
  font-weight: bold;
  font-size: 35px;
  white-space: nowrap;
`
const Content = styled.section`
  width: 80%;
`
const ContentHolder = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  &:hover {
    background-color: darkgrey;
  }
`
const ContentIcon = styled.img.attrs((props) => ({
  src: props.thumb,
  alt: 'Bad web icon'
}))`
  max-width: 15%;
  min-width: 40px;
`
const ContentText = styled.section`
  width: 75%;
  background: none;
`

const icons = [
  {name: 'web', file: web},
  {name: 'certs', file: certs},
  {name: 'history', file: history},
  {name: 'phone', file: phone},
  {name: 'power', file: power},
]

export function StartMenu({setShowHistory, setShowQuals, setShowContact, setShowWeb, setShowStart}) {
  const onClick = (e) => {
    //Only show one at a time
    setShowHistory(false)
    setShowQuals(false)
    setShowContact(false)
    setShowWeb(false)
    setShowStart(false) //And close the start menu
    switch (e.id) {
      case 1: //Web page
        setShowWeb(true)
        break;
      case 2: //History
        setShowHistory(true)
        break;
      case 3: //Qualifications
        setShowQuals(true)
        break;
      case 4: //Contact
        setShowContact(true)
        break;
      case 5: //Shut down
        window.location.href='https://www.google.com'
        break;
      default:
        console.log('Something went wrong.')
        break;
    }
  }

  return (
    <StartMenuStyle>
      <LeftBar>
        <BarText>BCOS-97</BarText>
      </LeftBar>
      <Content>
        {items.startMenu.map((item) =>
          <ContentHolder key={item.id}
                         logo={item.logo}
                         onClick={() => onClick(item)}>
            <ContentIcon thumb={icons.filter((icon) => {return icon.name === item.logo})[0].file}></ContentIcon>
            <ContentText>{item.name}</ContentText>
          </ContentHolder>
        )}
      </Content>
    </StartMenuStyle>
  )
}