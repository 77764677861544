import styled from "styled-components"
import { findZ } from './FindZ'

const ButtonStyle = styled.section`
  @keyframes bgColour {
    from {background-color: lightblue;}
    to {background-color: lightgrey}
  }
  display: flex;
  background: lightgrey;
  animation-name: bgColour;
  animation-duration: 1s;
  min-width: 80px;
  height: 35px;
  margin: 3px;
  border-style: inset;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  border-radius: 6px;
  @media (max-width: 768px) {
    width: 120px;
    margin: 1px;
    font-size: smaller;
  };
  &:hover {
    background-color: darkgrey;
  }
`
export function TaskbarButton ({label}) {
  const onTaskbarClick = (e) => {
    document.getElementById(e.target.innerHTML).style.zIndex = findZ()
  }

  return (
    <ButtonStyle onClick={onTaskbarClick}>
      {label}
    </ButtonStyle>
  )
}