import './App.css';
import { AppRoutes } from './AppRoutes'
import { LoadingScreen } from './components/LoadingScreen'
import { useEffect, useState } from 'react'

function App() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false)
    }, 3000)
    return () => clearTimeout(delay)
  }, [])

  return (
    <div className="App">
      {loading && <LoadingScreen />}
      {!loading && <AppRoutes />}
    </div>
  )
}

export default App;

//Icons courtesy of freeicons.io  CC(Attribution-NonCommercial)