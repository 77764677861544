import styled from "styled-components"

const HistoryStyle = styled.section`
  text-align: justify;
  padding-right: 10px;
  height: 95%;
  overflow-y: scroll;
  @media (max-width: 768px) {
    font-size: 0.8em;
    height: 92%;
    width: 95%;
    padding-right: 0;
  };
`
const HStyle = styled.section`
  font-size: 1.2em;
  font-weight: bold;
  @media (max-width: 768px) {
    font-size: 1em;
  };
`
const IStyle = styled.section`
  font-size: 0.7em;
  font-style: italic;
  @media (max-width: 768px) {
    font-size: 1em;
  };
`
const Hdiv = styled.section`
  margin-bottom: 30px;
`
const OL = styled.ol`
  @media (max-width: 768px) {
    padding-inline-start: 20px;
  };
`

export function History () {
  return (
    <HistoryStyle>
      <OL>
        <li>
          <Hdiv>
            <HStyle>Lead Engineer/Scrum Master, Voyager Worldwide Ltd</HStyle>
            <IStyle>October 2018 - present</IStyle>
            <IStyle>(Web Developer October 2018; Senior Web Developer March 2021, Lead July 2023)</IStyle>
            <p>Leading a remote agile development team doing full-stack
              Javascript/Typescript and SQL development (Node, Docker, React, Ember,
              HTML/CSS), delivering a range of business functions to the global
              customer service teams.  Heavily involved in ERP systems migration
              following merger in 2021, and expansion into Azure platform 2022 onwards.</p>
          </Hdiv>
        </li>
        <li>
          <Hdiv>
            <HStyle>Fulltime Student, Robert Gordon University</HStyle>
            <IStyle>August 2017 - September 2018</IStyle>
            <p>MSc in IT and Network Management; awarded with Distinction.  Final
            project was an Agile development management system, utilising PHP with
            a MySQL backend to manage task status, backlog priorities, etc.</p>
          </Hdiv>
        </li>
        <li>
          <Hdiv>
            <HStyle>Engineer - Remote Intervention, TechnipFMC</HStyle>
            <IStyle>June 2014 - August 2017</IStyle>
            <p>Supporting the ROV and Subsea Tooling assets for the fleet, including
              bespoke design, management of sub-contractors and suppliers and
              maintenance activities. Also acting as Project Engineer both onshore
              (ops reviews, procedures etc) and offshore for a major UK project,
              and proud to represent the company as part of the IMCA Digital Video
              Workgroup.</p>
          </Hdiv>
        </li>
        <li>
          <Hdiv>
            <HStyle>Design Engineer - Kongsberg Maritime Ltd</HStyle>
            <IStyle>May 2006 - May 2014</IStyle>
            <IStyle>(Student May 2006-August 2007; Permanent August 2007-May 2014)</IStyle>
            <p>Involved with all areas of the product lifecycle, from sales
              presentations and customer interactions, product specifications,
              and the bids and tenders process through system and component-level
              design, testing, documentation and delivery and field-testing,
              including supporting the Customer, liaising with other departments
              and coordinating with colleagues on larger projects.</p>
          </Hdiv>
        </li>
        <li>
          <Hdiv>
            <HStyle>Customer Advisor - Home Retail Group PLC</HStyle>
            <IStyle>June 2005 - February 2008</IStyle>
            <p>Working closely with colleagues and customers in a fast-paced
              sales environment. Responsible for catering to customer requirements,
              overseeing stockroom operations and supervising junior colleagues.</p>
          </Hdiv>
        </li>
        <li>
          <Hdiv>
            <HStyle>Bar Staff - Aberdeen Leisure Beach</HStyle>
            <IStyle>June 2004 - October 2004</IStyle>
            <p>Employed as a barman at the Aberdeen Leisure Beach, responsible
              for serving customers in a timely fashion, requiring excellent
              communication skills, a cool head under pressure, teamwork and
              patience.</p>
          </Hdiv>
        </li>
      </OL>
    </HistoryStyle>
  )
}