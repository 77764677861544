import styled from "styled-components"

const ScanlineStyle = styled.section`
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, .2) 4px, rgba(0, 0, 0, .2) 8px),
    linear-gradient(lightblue, lightblue);
  background-repeat: repeat-y;
  pointer-events: none;
  height: 100%;
  width: 100%;
`

export function Scanlines({children}) {
  return (
    <ScanlineStyle>{children}</ScanlineStyle>
  )
}