export const findZ = () => {
  let items = document.querySelectorAll('body *')
  let max = 1
  items.forEach((item) => {
    let zIndex = Number.parseInt(document.defaultView.getComputedStyle(item, null).getPropertyValue('z-index'))
    if (zIndex > max && zIndex !== 1000) {
      max = zIndex
    }
  })
  return max+1
}