import styled from "styled-components"

const FileHolderStyle = styled.div`
  text-align: center;
  width: 120px;
  hyphens: manual;
  @media (max-width: 768px) {
    width: 60px;
  };
`

const FileHolderImage = styled.div`
  background-image: url(${props => props.thumb});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 80px;
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    margin-left: 10px;
    margin-right: 15px;
    margin-bottom: 3px;
  };
`
const FileShadow = styled.div`
  height: 10px;
  width: 60px;
  margin-left: auto;
  margin-right: auto;
  background-image: radial-gradient(rgba(0,0,0,0.3) 10%, rgba(0,0,0,0) 70%);
  @media (max-width: 768px) {
    width: 30px;
    height: 10px;
};
`

const FileHolderLabel = styled.section`
  margin-top: 5px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: .7em;
  };
`

const drag = (e) => {
  e.dataTransfer.setData("text/html", JSON.stringify([e.target.id,
    e.nativeEvent.offsetX, e.nativeEvent.offsetY, 'file']
  ))
}

export function FileHolder({thumb, label, altText, onClick}) {
  return (
    <FileHolderStyle id={(label+'fileHolder')} onClick={onClick} draggable onDragStart={(e) => {drag(e)}}>
      <FileHolderImage thumb={thumb} altText={altText}></FileHolderImage>
      <FileShadow/>
      <FileHolderLabel>{label}</FileHolderLabel>
    </FileHolderStyle>
  )
}