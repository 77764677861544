import styled from "styled-components"
import { useEffect, useState } from 'react'
import { TextStyle } from './TextStyle'

const TaskBarStyle = styled.section`
  background: darkgrey;
  width: 10%;
  min-width: 80px;
  height: 40px;
  margin: 0px;
  border-style: inset;
  position: fixed;
  right: 0px;
  bottom: 0px;
`

export function TaskBar() {
  const [now, setNow] = useState(new Date().toLocaleTimeString())
  const [today, setToday] = useState(new Date().toLocaleDateString())

  useEffect(() => {
    let timer = setInterval(() => {
      setNow(new Date().toLocaleTimeString())
      setToday(new Date().toLocaleDateString())
    }, 1000)
    return () => clearInterval(timer)
  })

  return (
    <TaskBarStyle>
      <TextStyle>{now}</TextStyle>
      <TextStyle>{today}</TextStyle>
    </TaskBarStyle>
  )
}