import styled from 'styled-components'
import { PopupTop } from './PopupTop'
import { findZ } from './FindZ'

const BigPopupStyle = styled.section`
  width: 60%;
  min-width: 500px;
  height: 70%;
  background: white;
  border: 1px solid black;
  position: fixed;
  left: 15%;
  top: 15%;
  @media (max-width: 768px) {
    min-width: unset;
    width: 99%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    font-size: smaller;
  };
  z-index: ${findZ()};
  box-shadow: 5px 10px 10px grey;
`

const drag = (e) => {
  e.dataTransfer.setData("text/html", JSON.stringify([e.target.id,
    e.nativeEvent.offsetX, e.nativeEvent.offsetY]
  ))
}

export const LargePopup = ({title, close, corner, children}) => {
  return (
    <BigPopupStyle id={title} draggable onDragStart={(e) => {drag(e)}}>
      <PopupTop title={title} close={close} corner={corner}/>
      {children}
    </BigPopupStyle>
  )
}