import styled from "styled-components"

const AboutStyle = styled.section`
  text-align: justify;
  
`
export function About () {
  return (
    <AboutStyle>
      Welcome to my frontpage!<br/><br/>
      All my own work, all rights reserved.<br/><br/><br/>
      This site was built in <a href={'https://reactjs.org'} target={'_blank'} rel={'noreferrer'}>ReactJS</a>,
        using <a href={'https://www.jetbrains.com/phpstorm/'} target={'_blank'} rel={'noreferrer'}>JetBrains PHPStorm</a>, and hosted
      by <a href={'https://vercel.com/home'} target={'_blank'} rel={'noreferrer'}>Vercel</a>.  It takes advantage of CI/CD via Github,
      and SSL/CDN through <a href={`https://www.cloudflare.com/`}>Cloudflare</a>.<br/>
      <strong>Note</strong> that links will open in a new window/tab, and may not support SSL due to using InfinityFree hosting for PHP.<br /><br />
      It includes icons from <a href={'https://freeicons.io'} target={'_blank'} rel={'noreferrer'}>freeicons.io</a> (Creative Commons 3.0 Non-commercial). <br/>
      "Web" content courtesy of <a href={'https://en.wikipedia.org'} target={'_blank'} rel={'noreferrer'}>Wikipedia.org</a>'s Rest API for random articles.
    </AboutStyle>
  )
}