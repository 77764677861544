import styled from "styled-components"

const TextStyled = styled.section`
  font-size: 14px;
  font-weight: bold;
`

export function TextStyle({children}) {
  return (
    <TextStyled>{children}</TextStyled>
  )
}