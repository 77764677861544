import styled from "styled-components"
import { PopupTop } from './PopupTop'
import { PopupBody } from './PopupBody'
import { findZ } from './FindZ'
import { useEffect, useState } from 'react'

const PopupHolderStyle = styled.section`
  width: 600px;
  min-width: 375px;
  height: 500px;
  background: white;
  border: 1px solid black;
  position: fixed;
  left: ${(props) => props.position}%;
  top: ${(props) => props.position}%;
  @media (max-width: 768px) {
    width: 99%;
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
  };
  z-index: ${findZ()};
  box-shadow: 5px 10px 10px grey;
`

const drag = (e) => {
  e.dataTransfer.setData("text/html", JSON.stringify([e.target.id,
    e.nativeEvent.offsetX, e.nativeEvent.offsetY]
  ))
}

export function PopupHolder({title, close, corner, children}) {
  const [position, setPosition] = useState(15)

  useEffect(() => {
    setPosition(getPosition())
  }, [])

  const getPosition = () => {
    return 15 + Math.floor(Math.random() * 10) * 3
  }

  return (
    <PopupHolderStyle id={title} draggable onDragStart={(e) => {drag(e)}} position={position}>
      <PopupTop title={title} close={close} corner={corner}></PopupTop>
      <PopupBody>
        {children}
      </PopupBody>
    </PopupHolderStyle>
  )
}
//todo - try calculating zindex on render?